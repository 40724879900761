import { Fragment } from 'react'

import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import withFullHeight from 'hocs/with-full-height'
import Logo from 'microcomponents/icons/logo'
import { SectionTitle, Text } from 'microcomponents/typography'

import { shape, string } from 'prop-types'

const Info = (props) => (
  <Fragment>
    <Navbar>
      <NavLogo />
    </Navbar>
    <Body>
      <BackgroundLogo />
      <Center>
        <SectionTitle color="white">{props.title}</SectionTitle>
        <br />
        <Text color="white">{props.body}</Text>
        <Cta>
          {props.ctaText && <Text color="white">{props.ctaText}</Text>}
          <CtaBtn target={props.ctaBtn.target} href={props.ctaBtn.link}>
            {props.ctaBtn.text}
          </CtaBtn>
        </Cta>
      </Center>
    </Body>
  </Fragment>
)

Info.propTypes = {
  title: string,
  body: string,
  ctaText: string,
  ctaBtn: shape({
    link: string,
    target: string,
    text: string
  })
}

Info.defaultProps = {
  title: 'Something went wrong...',
  body: '',
  ctaText: '',
  ctaBtn: {
    link: '/menu',
    target: '',
    text: 'Browse Menu'
  }
}

const hasFullHeight = withFullHeight(Info)

export default hasFullHeight

const Navbar = styled.div`
  background-color: white;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const hue = keyframes`
  from {
    filter: hue-rotate(0deg);
  }

  to {
    filter: hue-rotate(-360deg);
  }
`

const Body = styled.div`
  height: calc(100% - 5rem);
  width: 100%;
  background-color: #00aced;
  z-index: 0;
  animation: ${hue} 60s linear infinite;
`

const Svg = styled.svg`
  height: calc(100% - 5rem);
  right: 20%;
  position: absolute;
  z-index: 1;
  color: rgba(255, 255, 255, 0.15);
  width: 100%;
  top: 0;

  @media (max-width: 767px) {
    width: unset;
    right: 5%;
  }
`

const Center = styled.div`
  height: 100%;
  width: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: relative;
  flex-direction: column;
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 2rem;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Cta = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const CtaBtn = styled.a`
  background-color: white;
  border: white;
  color: #00aced !important;
  margin: 0 2rem;
  padding: 1rem;
  border-radius: 0.4rem;
  cursor: pointer;
  text-decoration: none;

  @media (max-width: 767px) {
    margin: 0 2rem;
  }
`

const BackgroundLogo = (props) => (
  <Svg viewBox="0 0 853 1158" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink">
    <path
      d="M242.976 803.65c24.377 66.806 65.883 124.021 127.704 154.337 74.449 36.71 162.404 11.024 214.232-29.324 76.865-59.86 107.171-172.637 109.806-313.965.66-46.963-8.674-93.264 6.479-129.313 8.254-21.353 27.204-36.682 49.742-40.237 36.163-4.425 70.567 16.848 82.904 51.262a336.646 336.646 0 0 1 15.153 81.467 706.683 706.683 0 0 1-20.534 259.727c-28 103.627-77.852 180.244-147.14 235.805a358.334 358.334 0 0 1-126.607 65.153 437.58 437.58 0 0 1-170.968 17.418c-109.806-12.899-186.67-68.24-245.636-135.817C79.145 952.586 36.759 862.96 14.688 760.436-8.152 654.274-3.1 519.119 21.167 413.839c34.37-149.927 103.437-281.224 214.232-359.936 41.256-30.958 90.41-49.536 141.76-53.577 114.528-5.401 184.145 56.885 217.526 138.022 35.797 86.98 28 211.11-1.098 305.367-26.601 83.664-72.155 160-133.085 223.016-59.725 63.477-134.596 110.603-217.526 136.92zm175.69-474.035c4.831-51.813 4.611-123.359-31.405-141.218a66.415 66.415 0 0 0-54.135 3.307c-34.698 17.198-57.538 58.207-73.57 97.784-38.871 96.13-58.087 238.67-47.656 362.912 109.477-58.538 191.722-163.928 206.326-322.564l.44-.22z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </Svg>
)

const NavLogo = (props) => <Logo fill="#00AAE7" />
